@use './assets/scss/themes';
@use './assets/scss/variables';
/* You can add global styles to this file, and also import other style files */
@import "node_modules/ol/ol.css";

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-weight: 300;
    font-family: Roboto Mono, 'Helvetica Neue', sans-serif;
    overflow: hidden;
    background: linear-gradient(to bottom, white, whitesmoke);
}

mat-row,
mat-footer-row {
    min-height: 40px !important;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

router-outlet + * {
    display: flex;
    flex-direction: column;
    height: 100%;
    animation: fade 0.5s;
}

app-root > router-outlet + * {
    display: flex;
    flex-direction: column;
    height: 100%;
}

app-root {
    display: block;
    height: 100%;
}

.my-dark-theme {
    .mat-simple-snack-bar-content {
        color: white;
    }
}

.my-light-theme {
    .mat-simple-snack-bar-content {
        color: white;
    }
}

.my-light-theme,
.my-dark-theme {
    .snackbar-custom {
        padding: 10px;

        .mdc-snackbar__surface {
            background-color: inherit;
        }

        .mat-simple-snack-bar-content {
            font-weight: bold;
            font-size: 14pt;
            color: white;
            display: inline-flex;
            align-items: center;
            overflow: visible;
        }

        .mdc-button {
            .mdc-button__label {
                color: white;
            }

            border: 1px white dashed;
            border-radius: 10px;

            &:hover {
                background-color: white;
                border: 1px black dashed;

                .mdc-button__label {
                    color: black;
                }
            }
        }

        .mat-mdc-snack-bar-label .mdc-snackbar__label {
            font-weight: bold;
        }
    }

    .toast-success {
        .mdc-snackbar__surface {
            background-color: #28a645;
        }
    }

    .toast-info {
        .mdc-snackbar__surface {
            background-color: #17a1b7;
        }
    }

    .toast-warning {
        .mdc-snackbar__surface {
            background-color: #fdc007;
        }
    }

    .toast-error {
        .mdc-snackbar__surface {
            background-color: #db3545;
        }
    }
}

.mat-tooltip-panel {
    pointer-events: all;
}

.mat-mdc-option > .mat-icon {
    left: -2px;
}
